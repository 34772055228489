.orcidAuthButton {
    padding: 2px;
    display: flex;
    position: relative;
    text-align: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    text-decoration: none;
}

.orcidAuthButton:hover{
    background-color: #e0e0e0;
    border: solid 1px #d3d3d3;
}